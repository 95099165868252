import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { chakra } from '@chakra-ui/react';
import Layout from '../components/layout';
import Seo from '../components/seo';

type Props = {
  data: {
    pageData: {
      title: string;
    };
    seoData: {
      seo: {
        metaDescription: string;
        metaKeywords: string;
      };
    };
  };
};

const Booking = ({ data: { pageData, seoData } }: Props) => {
  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute(
      'src',
      'https://assets.calendly.com/assets/external/widget.js'
    );
    if (head) head.appendChild(script);
  }, []);

  return (
    <Layout invertedFooter={true}>
      <Seo {...seoData.seo} />
      <chakra.div
        className="calendly-inline-widget"
        data-url="https://calendly.com/info-kudo?primary_color=68744d"
        height="840px"
      ></chakra.div>
    </Layout>
  );
};

export default Booking;

export const pageQuery = graphql`
  query BookingQuery($id: String!) {
    pageData: wpPage(id: { eq: $id }) {
      title
    }
    seoData: wpPage(id: { eq: $id }) {
      seo {
        metaDescription
        metaKeywords
      }
    }
  }
`;
